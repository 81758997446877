import React, { useState } from 'react';
import { TableContainer } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from '~/context/global';
import { HouseholdMemberModal } from './HouseholdMemberModal';
import { HouseholdMembersTable } from './HouseholdMembersTable';
import { HouseholdMember } from '~/context/global/user/account';
import { RemoveHouseholdMemberDialog } from './RemoveHouseholdMemberDialog';
import { useHouseholdMembers } from '~/state/hooks/useHouseholdMembers';
import { useUserDemographics } from '~/state/hooks/useUserDemographics';
import * as Styled from './styles';

export const HouseholdMembersList: React.FC = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { hasUserDemographics } = useUserDemographics();
  const { activeHouseholdMembers } = useHouseholdMembers();
  const { householdMembers } = useGlobalState().user.account;
  const [memberToEdit, setMemberToEdit] = useState<HouseholdMember>();
  const [memberToRemove, setMemberToRemove] = useState<HouseholdMember>();

  const tPath = 'HouseholdMembers';

  const scrollToTop = () =>
    window?.scrollTo?.({ top: 0, left: 0, behavior: 'smooth' });

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setMemberToEdit(null);
    setOpenModal(false);
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMemberToRemove(null);
  };

  const openAddHouseholdMember = () => {
    setMemberToEdit(null);
    handleOpenModal();
  };

  const openEditHouseholdMember = (member: HouseholdMember) => {
    setMemberToEdit(member);
    handleOpenModal();
  };

  const openRemoveHouseholdMember = member => {
    setMemberToRemove(member);
    handleOpenDialog();
  };

  return (
    <>
      <HouseholdMemberModal
        open={openModal}
        member={memberToEdit}
        handleClose={handleCloseModal}
      />
      {memberToRemove && (
        <RemoveHouseholdMemberDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          member={memberToRemove}
        />
      )}
      <Styled.CardTitle>{t(`${tPath}.heading`)}</Styled.CardTitle>
      <Styled.Card>
        <Styled.Description>{t(`${tPath}.description`)}</Styled.Description>
        {!hasUserDemographics && (
          <Styled.Description>
            <Trans i18nKey={`${tPath}.demographicsRequired`}>
              <Styled.ScrollTopButton onClick={scrollToTop} />
            </Trans>
          </Styled.Description>
        )}
        <Styled.AddMemberButton
          onClick={() => openAddHouseholdMember()}
          disabled={!hasUserDemographics}
          data-cy="add-household-member-button"
        >
          {t(`${tPath}.addMemberButtonLabel`)}
        </Styled.AddMemberButton>
        {activeHouseholdMembers?.length > 0 && (
          <TableContainer>
            <HouseholdMembersTable
              householdMembers={householdMembers}
              onEditButtonClick={openEditHouseholdMember}
              onRemoveButtonClick={openRemoveHouseholdMember}
            />
          </TableContainer>
        )}
      </Styled.Card>
    </>
  );
};

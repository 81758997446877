import React, { useContext } from 'react';
import { oldestBirthYear, youngestBirthYear } from '~/common/generalUtils';
import { enUS as en, fr, es } from 'date-fns/locale';
import { CountryContext } from '~/context/i18n';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useTheme } from '@material-ui/core';

interface Props extends KeyboardDatePickerProps {
  errorMessage?: string;
}

export const DateOfBirthInput: React.FC<Props> = props => {
  const { language } = useContext(CountryContext);

  const [languageLocale] = language.split('-');
  const localeMap = { en, es, fr };
  const theme = useTheme();

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={localeMap[languageLocale]}
    >
      <KeyboardDatePicker
        autoOk
        fullWidth
        disableFuture
        format="MM/yyyy"
        maxDate={new Date(youngestBirthYear, 0)}
        minDate={new Date(oldestBirthYear, 0)}
        views={['year', 'month']}
        inputVariant="outlined"
        variant="inline"
        openTo="year"
        style={{ backgroundColor: theme.palette.common.white }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

import React, { useState, useContext } from 'react';
import { useGlobalDispatch } from '~/context/global';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { CountryContext } from '~/context/i18n';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { useAccountInformationForm } from './useAccountInformationForm';
import { AccountInformationForm } from './types';
import { CardContent, CardActions, CircularProgress } from '@material-ui/core';
import { NotificationSettings } from './NotificationSettings';
import { RaceSelect } from '~/components/forms/fields/RaceSelect';
import {
  updateUserAccount,
  UpdateUserAccountRequestBody,
} from '~/requests/user';
import { oldestBirthYear, convertDateToISO } from '~/common/generalUtils';
import { SelectOptions } from '~/components/forms/fields/Select';
import { DateOfBirthInput } from '~/components/forms/fields/DateOfBirthInput';
import { logger, ONMFeature } from '~/services/logging';
import { transform, isEmpty } from 'lodash';
import * as Styled from './styles';
import { EthnicitySelect } from '~/components/forms/fields/EthnicitySelect';

export const AccountInformation: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useGlobalDispatch();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { country } = useContext(CountryContext);
  const { form, validationSchema } = useAccountInformationForm();

  const tPath = 'AccountSettings.form';
  const genderOptions: SelectOptions = t(`${tPath}.gender.options`, {
    returnObjects: true,
  });
  const countryCodeOptions: SelectOptions = t(`${tPath}.countryCode.options`, {
    returnObjects: true,
  });

  const buildRequest = (
    values: Partial<AccountInformationForm>
  ): UpdateUserAccountRequestBody => {
    const requestBody: UpdateUserAccountRequestBody = {
      gender: values.gender,
      raceId: values.raceId,
      ethnicityId: values.ethnicityId,
      userSubmittedRace: values.userSubmittedRace,
      countryCode: values.countryCode,
      zipcode: values.zipcode,
      nickname: values.nickname,
      dateOfBirth: values.dateOfBirth
        ? convertDateToISO(values.dateOfBirth)
        : undefined,
      remindByPhone: values.notificationPreference
        ? /text/i.test(values.notificationPreference)
        : undefined,
      remindByEmail: values.notificationPreference
        ? /email/i.test(values.notificationPreference)
        : undefined,
      phoneNumber: values.phoneNumber
        ? country.phoneCode + values.phoneNumber
        : undefined,
    };

    for (const field in requestBody) {
      if (requestBody[field] === undefined) {
        delete requestBody[field];
      }
    }

    return requestBody;
  };

  const getUpdatedFields = (
    formValues: AccountInformationForm
  ): Partial<AccountInformationForm> => {
    const defaultValues = form.control.defaultValuesRef.current;
    return transform(
      formValues,
      (res, val, key) => {
        if (val !== defaultValues[key]) res[key] = val;
      },
      {}
    );
  };

  const submitForm = async (values: AccountInformationForm) => {
    setLoading(true);

    try {
      const accountUpdate = getUpdatedFields(values);
      if (isEmpty(accountUpdate)) {
        return;
      }

      const requestBody = buildRequest(accountUpdate);
      const { data, status } = await updateUserAccount(requestBody);
      if (status !== 200) {
        throw Error();
      }

      dispatch({ type: 'UPDATE_USER_ACCOUNT_DATA', payload: data });
      showSnackbar(t('AccountSettings.submitSuccess'), 'success');
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode ?? 'Unknown';
      logger.error(ONMFeature.ACCOUNT_UPDATE, error);
      showSnackbar(t(`AccountSettings.form.errors.${errorCode}`), 'error');
    } finally {
      setLoading(false);
    }
  };

  const getErrorMessage = (
    fieldName: keyof AccountInformationForm,
    errType?: string
  ): string => {
    if (!errType) return '';

    if (errType === 'maxAge') {
      return t(`${tPath}.${fieldName}.errors.${errType}`, {
        oldestBirthYear,
      });
    }

    if (
      fieldName === 'userSubmittedRace' ||
      fieldName === 'raceId' ||
      fieldName === 'ethnicityId' ||
      fieldName === 'zipcode'
    ) {
      return t(`FormField.${fieldName}.errors.${errType}`);
    }

    return t(`${tPath}.${fieldName}.errors.${errType}`);
  };

  form.register('dateOfBirth', validationSchema.get('dateOfBirth'));

  return (
    <FormProvider {...form}>
      <Styled.CardTitle>{t(`${tPath}.title`)}</Styled.CardTitle>
      <Styled.FormCard>
        <CardContent>
          <Styled.Input
            name="nickname"
            data-test="nickname_input"
            error={!!form.errors.nickname}
            label={t(`${tPath}.nickname.label`)}
            InputLabelProps={{ shrink: !!form.watch('nickname') }}
            inputRef={form.register(validationSchema.get('nickname'))}
            helperText={getErrorMessage(
              'nickname',
              form.errors?.nickname?.type
            )}
          />
          <Styled.Select
            showBlankDefault
            name="gender"
            variant="outlined"
            options={genderOptions}
            formControl={form.control}
            label={t(`${tPath}.gender.label`)}
            validationRules={validationSchema.get('gender')}
            errorMessage={getErrorMessage('gender', form.errors?.gender?.type)}
          />
          <RaceSelect
            form={form}
            getErrorMessage={getErrorMessage}
            validationSchema={validationSchema}
          />
          <EthnicitySelect
            form={form}
            getErrorMessage={getErrorMessage}
            validationSchema={validationSchema}
          />
          <DateOfBirthInput
            name="dateOfBirth"
            value={form.watch('dateOfBirth')}
            label={t(`${tPath}.dateOfBirth.label`)}
            onChange={date =>
              form.setValue('dateOfBirth', date, { shouldValidate: true })
            }
            helperText={getErrorMessage(
              'dateOfBirth',
              form.errors?.dateOfBirth?.type
            )}
            error={!!form.errors.dateOfBirth}
          />
          <Styled.Select
            name="countryCode"
            variant="outlined"
            options={countryCodeOptions}
            formControl={form.control}
            label={t(`${tPath}.countryCode.label`)}
            validationRules={null}
            errorMessage={getErrorMessage(
              'countryCode',
              form.errors?.gender?.type
            )}
          />
          <Styled.Input
            name="zipcode"
            data-test="zipcode_input"
            error={!!form.errors.zipcode}
            label={t(`${tPath}.zipcode.label`)}
            InputLabelProps={{ shrink: !!form.watch('zipcode') }}
            inputRef={form.register(validationSchema.get('zipcode'))}
            helperText={getErrorMessage('zipcode', form.errors?.zipcode?.type)}
          />
          <NotificationSettings
            shrinkSelectLabel
            form={form}
            getErrorMessage={getErrorMessage}
            validationSchema={validationSchema}
            translationPath="AccountSettings.form"
            variant="outlined"
          />
        </CardContent>
        <CardActions>
          <Styled.SubmitButton
            type="submit"
            disabled={!form.formState.isDirty}
            onClick={form.handleSubmit(submitForm)}
            endIcon={loading && <CircularProgress color="inherit" size={18} />}
          >
            {t(`${tPath}.submitButton`)}
          </Styled.SubmitButton>
        </CardActions>
      </Styled.FormCard>
    </FormProvider>
  );
};
